import React from 'react'
import { graphql, PageProps } from 'gatsby'
import { PageContextProps, PageInfoLinkProps } from '../../types/pages'
import { PostPreviewWithCategoriesProps } from '../../types/blog'
import { ButtonProps } from '../../types/buttons'
import { ITutorial, ITutorialCard } from '../../types/resources'
import { GatsbyImageBasicProps } from '../../types/images'
import { Layout, Seo } from '../../components/commons'
import { TutorialHero, TutorialArticle } from '../../components/resources/tutorials'
import LogoList from '../../components/sections/LogoList'
import Testimonial from '../../components/sections/Testimonial'
import Push from '../../components/sections/Push/Push'
import FeaturedArticles from '../../components/sections/FeaturedArticles'
import FeaturedTutorials from '../../components/sections/FeaturedTutorials'

interface TutorialQuery {
  utils: {
    tutorialLabel: string
    tutorialsLanding: PageInfoLinkProps
    tutorialsIcon: GatsbyImageBasicProps
    tutorialsRelatedLabel: string
    tutorialsAllButton: ButtonProps[]
  }
  tutorial: Pick<
    ITutorial,
    | 'pageInfo'
    | 'meta'
    | 'name'
    | 'excerpt'
    | 'integrations'
    | 'buttons'
    | 'tags'
    | 'content'
    | 'logos'
    | 'testimonials'
    | 'push'
  >
  datoCmsGlobal: {
    blogRelatedLabel: string
    blogAllButton: ButtonProps[]
    blogContactButton: ButtonProps[]
    tutorials: ITutorialCard[]
    postPushHeadline: string
    postPushButton: ButtonProps[]
    postTableOfContentLabel: string
    postShareLabel: string
  }
  relatedPosts: { nodes: PostPreviewWithCategoriesProps[] }
  relatedTutorials: { nodes: Array<ITutorialCard> }
}

const Tutorial: React.FC<PageProps<TutorialQuery, PageContextProps & { baseIntegrationId: string }>> = ({
  data,
  pageContext,
}) => {
  const { utils, tutorial, relatedTutorials, relatedPosts, datoCmsGlobal } = data

  return (
    <Layout
      pageId={pageContext.id}
      locale={pageContext.locale}
      allSlugLocales={pageContext.slugLocales}
      withoutPrefooter={data.tutorial.pageInfo.hidePrefooter}
    >
      <Seo
        slug={data.tutorial.pageInfo.slug}
        canonicalUrl={data.tutorial.pageInfo.canonicalUrl}
        locale={pageContext.locale}
        allSlug={pageContext.slugLocales}
        publication={data.tutorial.meta}
        content={data.tutorial.pageInfo.seo}
      />
      <TutorialHero
        tagLabel={utils.tutorialLabel}
        tagLink={utils.tutorialsLanding}
        name={tutorial.name}
        excerpt={tutorial.excerpt}
        integrations={tutorial.integrations}
        buttons={tutorial.buttons}
      />
      <TutorialArticle
        tags={tutorial.tags}
        content={tutorial.content}
        articleSideButton={datoCmsGlobal.postPushButton}
        articleSideHeadline={datoCmsGlobal.postPushHeadline}
        articleSideShareLabel={datoCmsGlobal.postShareLabel}
        articleSideTableOfContentLabel={datoCmsGlobal.postTableOfContentLabel}
      />
      {data.relatedPosts.nodes.length > 0 && (
        <FeaturedArticles
          sectionId={''}
          headline={datoCmsGlobal.blogRelatedLabel}
          button={datoCmsGlobal.blogAllButton}
          articles={relatedPosts.nodes}
          id={''}
          model={{ apiKey: 's_featured_articles' }}
          bottomButton={datoCmsGlobal.blogContactButton}
        />
      )}
      {data.tutorial.logos.length > 0 && <LogoList {...data.tutorial.logos[0]} />}
      {data.tutorial.testimonials.length > 0 && (
        <Testimonial
          id={data.tutorial.testimonials[0].id}
          sectionId={''}
          model={{ apiKey: 's_testimonial' }}
          testimonial={data.tutorial.testimonials[0]}
        />
      )}
      {data.relatedTutorials.nodes.length > 0 && (
        <FeaturedTutorials
          sectionId={''}
          icon={utils.tutorialsIcon}
          headline={utils.tutorialsRelatedLabel}
          button={utils.tutorialsAllButton}
          tutorials={relatedTutorials.nodes}
          id={''}
          model={{ apiKey: 's_featured_tutorials_list' }}
        />
      )}
      {data.tutorial.push && <Push id={''} model={{ apiKey: 's_push' }} sectionId={''} push={data.tutorial.push} />}
    </Layout>
  )
}

export const query = graphql`
  query TutorialQuery($locale: String!, $id: String!, $tagIds: [String!]!, $baseIntegrationId: String!) {
    datoCmsGlobal(locale: $locale) {
      blogRelatedLabel
      blogAllButton {
        ...buttonFragment
      }
      blogContactButton {
        ...buttonFragment
      }
      postPushHeadline
      postPushButton {
        ...buttonFragment
      }
      postTableOfContentLabel
      postShareLabel
    }

    utils: datoCmsResourcesGlobal(locale: $locale) {
      tutorialLabel
      tutorialsLanding: tutorialsPageInfo {
        ...pageInfoLinkFragment
      }
      tutorialsIcon {
        alt
        title
        gatsbyImageData(layout: CONSTRAINED, width: 80, placeholder: NONE)
      }
      tutorialsRelatedLabel
      tutorialsAllButton {
        ...buttonFragment
      }
    }

    relatedPosts: allDatoCmsPost(
      locale: $locale
      limit: 3
      sort: { fields: meta___firstPublishedAt, order: DESC }
      filter: {
        tags: { elemMatch: { id: { in: $tagIds } } }
        _allContentLocales: { elemMatch: { locale: { eq: $locale } } }
      }
    ) {
      nodes {
        ...postPreviewWithCategoriesFragment
      }
    }
    relatedTutorials: allDatoCmsTutorial(
      locale: $locale
      limit: 6
      filter: { id: { ne: $id }, integrations: { elemMatch: { id: { eq: $baseIntegrationId } } } }
    ) {
      nodes {
        ...tutorialCardFragment
      }
    }

    tutorial: datoCmsTutorial(locale: $locale, id: { eq: $id }) {
      meta {
        firstPublishedAt
        publishedAt
        updatedAt
      }
      pageInfo {
        ...pageInfoFragment
      }
      name
      excerpt
      integrations {
        id
        name
        icon {
          alt
          title
          gatsbyImageData(layout: CONSTRAINED, width: 120, height: 120, placeholder: NONE)
        }
      }
      buttons {
        ...buttonFragment
      }
      tags {
        ...resourceTagFragment
      }
      content {
        links
        value
        blocks {
          __typename
          ...blockImageFragment
          ...blockVideoFragment
          ...blockGatedContentFragment
          ...buttonFragment
        }
      }
      logos {
        ...sectionLogoListFragment
      }
      testimonials {
        ...testimonialFragment
      }
      push {
        ...pushFragment
      }
    }
  }
`

export default Tutorial
