import React from 'react'
import { SECTION_MARGIN } from '../../../utils/constants'
// Types
import { SectionTestimonial } from '../../../types/sections'
// Components
import { Grid, Row, Col } from 'react-styled-flexboxgrid'
import { Flex, Box, Heading, Text, Image, Button } from '../../../atoms'
import { StaticImage } from 'gatsby-plugin-image'
import { useLocale } from '../../../hooks/useLocale'

const Testimonial: React.FC<SectionTestimonial> = (props) => {
  const { id, sectionId, testimonial } = props

  const { locale } = useLocale()

  return (
    <Box as={'section'} id={sectionId || id}>
      <Box my={SECTION_MARGIN}>
        <Grid>
          <Row>
            <Col xs={12}>
              <Box position={'relative'} backgroundColor={'violetLight'} borderRadius={'small'} overflow={'hidden'}>
                <Box
                  position={'absolute'}
                  top={0}
                  left={0}
                  width={'100%'}
                  height={'100%'}
                  zIndex={1}
                  overflow={'hidden'}
                >
                  <Box position={'absolute'} top={-210} left={[-200, -50]}>
                    <StaticImage
                      formats={['auto']}
                      draggable={false}
                      src={'../../../images/ellipse_violet_testimonial_left.png'}
                      alt={''}
                      placeholder={'none'}
                      width={400}
                    />
                  </Box>
                  <Box position={'absolute'} bottom={[-200, -30]} right={[-260, -140]}>
                    <StaticImage
                      formats={['auto']}
                      draggable={false}
                      src={'../../../images/ellipse_violet_testimonial_right.png'}
                      alt={''}
                      placeholder={'none'}
                      width={465}
                    />
                  </Box>
                </Box>
                {testimonial && (
                  <Box position={'relative'} p={[6, 10, 80]} zIndex={2}>
                    <Box mb={6}>
                      <StaticImage
                        formats={['auto']}
                        draggable={false}
                        src={'../../../images/quote_symbol.png'}
                        alt={''}
                        placeholder={'none'}
                        width={50}
                      />
                    </Box>
                    <blockquote>
                      <Heading as={'h4'} type={'heading2'}>
                        {testimonial.quote}
                      </Heading>
                    </blockquote>

                    {testimonial.customerStory && (
                      <Box mt={3}>
                        <Button
                          buttonType={'text'}
                          link={testimonial.customerStory.pageInfo}
                          label={locale === 'fr' ? 'Lire le cas client' : 'Read customer story'}
                        />
                      </Box>
                    )}

                    <Flex mt={6} alignItems={'center'}>
                      {testimonial.clientLogo && (
                        <Box flex={'0 0 auto'} mr={6}>
                          <Image
                            draggable={false}
                            alt={testimonial.clientLogo.alt || testimonial.clientName || ''}
                            image={testimonial.clientLogo.gatsbyImageData}
                          />
                        </Box>
                      )}

                      <Box flex={'1'}>
                        {!!testimonial.clientName && (
                          <Text type={'large'} fontWeight={'bold'}>
                            {testimonial.clientName}
                          </Text>
                        )}
                        {!!testimonial.clientRole && (
                          <Box mt={1}>
                            <Text type={'small'}>{testimonial.clientRole}</Text>
                          </Box>
                        )}
                      </Box>
                    </Flex>
                  </Box>
                )}
              </Box>
            </Col>
          </Row>
        </Grid>
      </Box>
    </Box>
  )
}

export default Testimonial
